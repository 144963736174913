<template>
  <div class="position-relative">

    <div v-if="loading" class="text-right p-4 position-absolute w-100" style="z-index: 1">
      <div class="spinner-border text-secondary" role="status">
      </div>
    </div>
    <div id="map" style="min-height: calc(100vh - 100px)"></div>

  </div>
</template>

<script>
import MapBoxGL from 'mapbox-gl'
// import hull from 'hull.js'
import {getCenterPoint} from "../../utils/calcCenterPoint";
import treeImg from '../../assets/img/map-tree.png'
import * as turf from '@turf/turf'
import Area from "../../api/Area"
// import TreeType from "../../api/TreeType";
import {mapGetters} from "vuex";
// import Checkpoint from "@/api/Checkpoint";

export default {
  name: 'MapBoxFullComponent',

  data() {
    return {
      areas: null,
      loading: false,
      accessToken: 'pk.eyJ1IjoibWFnaW9kZXYiLCJhIjoiY2t3YzdjZ2ltMWZlczJ3cDJubXFheHl0bSJ9.B1spSbf4EfBOlDhYVmRZMQ',
      container: 'map', // container ID
      style: 'mapbox://styles/josedvd123/ckyubv4uz001v14lcg26hetvw', // style URL
      zoom: 7, // starting zoom
      map: {}
    }
  },
  computed: {
    ...mapGetters("user", ["getWeb2ActiveAccount"]),
  },

  mounted() {
    this.createMap()
  },

  methods: {
    async createMap() {
      // const firstAreaData = this.areas[10].checkpoints[this.areas[10].checkpoints.length - 1].data;
      // const firstTree = firstAreaData[Object.keys(firstAreaData)[0]].trees[0];

      // const center = [firstTree.lng, firstTree.lat];
      const center = [27.1545801, 61.8449184];
      MapBoxGL.accessToken = this.accessToken;
      this.map = new MapBoxGL.Map({
        container: this.container,
        style: this.style,
        center: center,
        zoom: this.zoom,
        optimize: true
      });

      this.loading = true;
      this.addLayers()
    },
    addLayers() {
      this.map.on('load', () => {
        this.map.loadImage(treeImg, async (error, image) => {
          if (error) throw error;

          this.map.addImage('tree-icon', image);
          const allPerimeterCoordinates = [];
          const centerPoints = [];
          const allPerimeters = [];

          const response = await Area.getAreasMap();
          this.areas = response.data;
          this.loading = false;

          for (let area of this.areas) {
            let perimeterCoordinates = area.perimeterCoordinates ? JSON.parse(area.perimeterCoordinates) : [];

            if (perimeterCoordinates.length > 3) {
              const centerPoint = getCenterPoint(perimeterCoordinates);

              const perimeterCoordinatesFeature = turf.polygon([perimeterCoordinates], {
                areaId: area.id,
                name: area.name,
                centerPoint
              });

              allPerimeterCoordinates.push(perimeterCoordinatesFeature);

              const coordinatesArea = +(turf.area(perimeterCoordinatesFeature) / 10000).toFixed(2);

              const centerPointFeature = turf.feature({
                  'type': 'Point',
                  'coordinates': centerPoint
                },
                {
                  areaId: area.id,
                  area: coordinatesArea,
                  name: area.name,
                  trees: area.qty,
                  centerPoint
                }
              );
              centerPoints.push(centerPointFeature);
            }

            for (const i in area.perimeters) {
              let geometry = area.perimeters[i];
              const geometryFeature = turf.feature([geometry]);
              allPerimeters.push(geometryFeature)


            }

          }


          this.map.addSource('perimeter-source', {
            'type': 'geojson',
            'data': turf.featureCollection(allPerimeterCoordinates)
          });

          this.map.addLayer({
            'id': 'perimeter',
            'type': 'fill',
            'source': 'perimeter-source', // reference the data source
            'layout': {},
            'minzoom': 11,
            // 'maxzoom': 13,
            'paint': {
              'fill-color': '#42b883', // blue color fill
              'fill-opacity': 0.2
            }
          });

          this.map.addLayer({
            'id': 'outline',
            'type': 'line',
            'source': 'perimeter-source',
            'layout': {},
            'minzoom': 11,
            // 'maxzoom': 13,
            'paint': {
              'line-color': '#0a7045',
              'line-width': 3
            }
          });

          this.map.addSource('area-source', {
            'type': 'geojson',
            'data': turf.featureCollection(centerPoints)
          });


          this.map.addLayer({
            "id": "area-units",
            "type": "symbol",
            minzoom: 11,
            "source": "area-source",
            "layout": {
              "symbol-placement": "point",
              "text-font": ["Open Sans Bold"],
              "text-field": '{trees} trees\n{area} ha', // part 2 of this is how to do it
              "text-size": 12,
            },
            'paint': {
              'text-color': '#0a7045'
            },
          });

          this.map.addLayer({
            'id': 'area',
            'type': 'symbol',
            'source': 'area-source',
            'maxzoom': 11,
            'layout': {
              'icon-allow-overlap': true,
              'icon-image': 'tree-icon',
              'icon-size': 0.3
            }
          });


          this.map.addSource(`perimeter-src`, {
            'type': 'geojson',
            'data': turf.featureCollection(allPerimeters)
          })

          this.map.addLayer({
            'id': `perimeter-line`,
            'type': 'line',
            'source': `perimeter-src`,
            minzoom: 11,
            'layout': {},
            'paint': {
              'line-color': '#000',
              'line-width': 2
            }
          });


          const popup = new MapBoxGL.Popup({
            closeButton: false,
            closeOnClick: false
          });

          let allLayers = ['perimeter', 'outline', "area-units", 'area']

          if (this.getWeb2ActiveAccount && this.getWeb2ActiveAccount.role.name === 'Administrator')
            this.map.on('click', allLayers, (e) => {
              const features = this.map.queryRenderedFeatures(e.point);

              const topFeature = features[0];
              if (topFeature?.properties?.areaId) {
                this.$router.push(`/areas/${topFeature.properties.areaId}`)

              }
            });

          this.map.on('mouseenter', allLayers, (e) => {
            this.map.getCanvas().style.cursor = 'pointer';
            const features = this.map.queryRenderedFeatures(e.point);
            for (const topFeature of features) {
              if (topFeature?.properties?.centerPoint) {
                popup.setLngLat(JSON.parse(topFeature.properties.centerPoint)).setHTML(`<span style="color: #4b6b41; font-weight: bold">${topFeature.properties.name}</span>`).addTo(this.map);
              }
            }
          });

          this.map.on('mouseleave', allLayers, () => {
            this.map.getCanvas().style.cursor = '';
            popup.remove();
          });

        });
      });
    }
  }
}
</script>

<style scoped>
.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #3A5333;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner-animation 1s linear infinite;
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
