<template>
  <b-container class="areas-map" fluid>
    <div v-if="getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'" id="areas-map-menu"
         class="position-absolute w-100 text-center">
      <router-link :to="'/areas-list'">
        <b-button class="mt-5" size="lg">Show Forests List</b-button>
      </router-link>
    </div>

    <MapBoxFullComponent/>
  </b-container>
</template>

<script>
import MapBoxFullComponent from '../../components/Area/MapBoxFullComponent'
import Area from "../../api/Area"
// import Checkpoint from "../../api/Checkpoint"
import {mapGetters} from "vuex";

export default {
  name: 'AreasMap',

  components: {
    MapBoxFullComponent
  },
  computed: {
    ...mapGetters("user", ["getWeb2ActiveAccount"]),
  },

  data() {
    return {
      areas: null
    }
  },

  created() {
    // this.getAreas()

      // Area.getAreas().then((response)=>{
      //   let areas = response.data;
      //   console.log({areas})
      //   for (const area of areas) {
      //     let treeTypes = area.last_checkpoint.data.treeTypes.map((treeType)=>{
      //       return {
      //         name: treeType.name,
      //         qty: treeType.qty,
      //         checkpoint: area.last_checkpoint.id,
      //         tree_type: treeType.id
      //       };
      //     });
      //
      //    Checkpoint.postCheckpointTreeTypes(treeTypes)
      //
      //   }


      // })
  },

  methods: {
    getAreas() {

      Area.getAreasMap().then((response) => {
        this.areas = response.data;
        console.log(this.areas.length, 'areas loaded');
      })


    }
  }
}
</script>

<style scoped>

</style>
